import React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import { Auth } from "aws-amplify";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import history from "../history";
import { useStyles } from "./Theme";
import Tooltip from "@material-ui/core/Tooltip";

function handleClick() {
  //console.log(history.location.pathname);
  if (history.location.pathname === "/dashboard") {
    return history.push("/");
  } else return history.goBack();
}

async function handleSignOut() {
  //console.log(history.location.pathname);
  await Auth.signOut();
  return history.push("/");
}

function Signout() {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={3} align="left">
        {history.location.pathname !== "/" ? (
          <Tooltip title="Back to previous screen">
            <IconButton aria-label="back" onClick={handleClick}>
              <ArrowBackIosIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={6} align="center">
        <img src="logo3.png" alt="logo" className={classes.logo} />
      </Grid>
      <Grid item xs={3} align="right">
        <Tooltip title="Signout from NeXus">
          <IconButton aria-label="sign out" onClick={handleSignOut}>
            <ExitToAppOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default Signout;
