import Amplify, { API, Auth } from "aws-amplify";

import awsConfig from "../config/aws-config-all-env";

Amplify.configure({
  API: awsConfig[process.env.REACT_APP_ENV_TYPE].apiGateway,
});

export async function getUserTradeData(userId) {
  try {
    const response = await API.get("lft-app-api", "/user-trade-config", {
      headers: {
        "x-lft-user": userId,
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    });

    return [response];
  } catch (err) {
    console.log("Get error", err);
    throw err;
  }
}

export async function setUserTradeData(newAlpacaConfig) {
  let response = null;
  const init = {
    body: { newAlpacaConfig },
    headers: {
      "x-lft-user": newAlpacaConfig.userId,
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };

  try {
    response = await API.post("lft-app-api", "/user-trade-config", init);
  } catch (err) {
    console.log("Post error", err);
    throw err;
  }
  return response;
}

export async function updateUserTradeData(newAlpacaConfig) {
  //console.log("user trade config update", newAlpacaConfig)
  let response = null;
  const init = {
    body: { newAlpacaConfig },
    headers: {
      "x-lft-user": newAlpacaConfig.userId,
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };

  try {
    response = await API.put("lft-app-api", "/user-trade-config", init);
    //console.log("put response", response);
  } catch (err) {
    console.log("Put error", err);
    throw err;
  }
  return response;
}

export async function deleteUserTradeData(userId) {
  let response = null;
  const init = {
    headers: {
      "x-lft-user": userId,
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };

  try {
    response = await API.del("lft-app-api", "/user-trade-config", init);
  } catch (err) {
    console.log("delete error", err);
    throw err;
  }
  return response;
}

export async function retrieveOrderStatistics(userId, resetTimestamp) {
  let response = null;
  const init = {
    headers: {
      "x-lft-user": userId,
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    queryStringParameters: {
      fromTimestamp: resetTimestamp,
    },
  };

  try {
    response = await API.get("lft-app-api", "/order-statistics", init);
    //console.log("response", response);
  } catch (err) {
    console.log("get order statistics error", err);
    throw err;
  }
  return response;
}

export async function retrieveDailyStatistics(userId, resetTimestamp) {
  //console.log("userId", userId);
  let response = null;
  const init = {
    headers: {
      "x-lft-user": userId,
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    queryStringParameters: {
      fromTimestamp: resetTimestamp,
    },
  };

  try {
    response = await API.get("lft-app-api", "/daily-statistics", init);
    //console.log("response", response);
  } catch (err) {
    console.log("get daily returns error", err);
    throw err;
  }
  return response;
}

export async function retrieveAlpacaOrders(userId, resetTimestamp) {
  //console.log("Entering retrieveAlpacaOrders - userId:", userId);

  const init = {
    headers: {
      "x-lft-user": userId,
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    queryStringParameters: {
      fromTimestamp: resetTimestamp,
    },
  };

  let alpacaOrders = null;
  try {
    alpacaOrders = await Promise.all([
      API.get("lft-app-api", "/open-orders", init),
      API.get("lft-app-api", "/closed-orders", init),
    ]);
  } catch (err) {
    console.log("retrieve orders error", err);
    throw err;
  }
  console.log("Exiting retrieveAlpacaOrders");
  return { openOrders: alpacaOrders[0], closedOrders: alpacaOrders[1] };
}
