import AlpacaOrderTable from "./AlpacaOrderTable";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import React from "react";
import { Grid } from "@material-ui/core";

export default function AlpacaDetail(props) {
  const { alpacaOpenOrders, alpacaClosedOrders } = props;
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <Paper square variant="outlined">
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="order tabs"
            >
              <Tab label="OPEN" />
              <Tab label="CLOSED" />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs />
        <Grid item xs />
      </Grid>

      <AlpacaOrderTable
        rows={tabValue === 0 ? alpacaOpenOrders : alpacaClosedOrders}
        isOpenOrders={tabValue === 0 ? true : false}
      />
    </>
  );
}
