export function getCurrentLocalDateTimeStr() {
  var stringDate = new Date().toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour12: true,
    minute: "2-digit",
    hour: "2-digit",
    second: "2-digit",
  });

  return stringDate;
}

export function getDateTimeFormatedStr(datatimeString) {
  var stringDate = new Date(datatimeString).toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour12: true,
    minute: "2-digit",
    hour: "2-digit",
    second: "2-digit",
  });

  return stringDate;
}

export function getCurrentFormattedDateUTCStr() {
  var newDate = new Date();
  return newDate.toISOString().split(/T(.+)/)[0];
}
