import React from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TablePagination from "@material-ui/core/TablePagination";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import BlockIcon from "@material-ui/icons/Block";
import { green, red, orange, blue } from "@material-ui/core/colors";
import { getDateTimeFormatedStr } from "../utils/DateUtils";

function ChildRow(props) {
  const { childRows, open } = props;
  childRows.sort((a, b) => b.order_type - a.order_type);

  return (
    <TableRow style={{ backgroundColor: blue[50] }}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={3}>
            <Typography variant="subtitle1" gutterBottom component="div">
              Related Orders
            </Typography>
            <Table size="small" aria-label="related orders">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Side</TableCell>
                  <TableCell align="left">Order Qty</TableCell>
                  <TableCell align="left">Fill Qty</TableCell>
                  <TableCell align="left">Fill Price</TableCell>
                  <TableCell align="left">Fill Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {childRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.order_type === "limit"
                        ? "Take Profit"
                        : row.order_type === "market"
                        ? "Market Auto Close"
                        : "Stop Loss"}
                    </TableCell>
                    <TableCell align="left">
                      {capitalize(row.status.replace("_", " "))}
                    </TableCell>
                    <TableCell align="left">{capitalize(row.side)}</TableCell>
                    <TableCell align="left">{row.qty}</TableCell>
                    <TableCell align="left">{row.filled_qty}</TableCell>
                    <TableCell align="left">
                      {row.filled_avg_price ? row.filled_avg_price : ""}
                    </TableCell>
                    <TableCell align="left">
                      {row.filledAmt ? row.filledAmt : ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}

const capitalize = (s) => (s ? s[0].toUpperCase() + s.slice(1) : null);

function Row(props) {
  const { row, isOpenOrders } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {row.order_class === "bracket" && (
            <>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </>
          )}
        </TableCell>
        {!isOpenOrders &&
          (row.status != "filled" ? (
            <TableCell>
              <BlockIcon style={{ color: orange[500] }} />
            </TableCell>
          ) : row.pl > 0 ? (
            // Profit order
            <TableCell style={{ color: green[500] }}>
              <TrendingUpIcon style={{ color: green[500] }} />
              <Typography variant="caption" display="block" gutterBottom>
                {row.pl} ({((row.pl / row.filledAmt) * 100).toFixed(2)}%)
              </Typography>
            </TableCell>
          ) : (
            // Loss order
            <TableCell style={{ color: red[500] }}>
              <TrendingDownIcon style={{ color: red[500] }} />
              <Typography variant="caption" display="block" gutterBottom>
                {row.pl} ({((row.pl / row.filledAmt) * 100).toFixed(2)}%)
              </Typography>
            </TableCell>
          ))}
        <TableCell component="th" scope="row" align="left">
          {getDateTimeFormatedStr(row.created_at)}
        </TableCell>
        <TableCell align="left"> {row.symbol} </TableCell>
        <TableCell align="left">
          {row.order_class === "bracket"
            ? "Bracket Order"
            : capitalize(row.order_type)}
        </TableCell>
        <TableCell align="left">{capitalize(row.side)}</TableCell>
        <TableCell align="left">
          {capitalize(row.status.replace("_", " "))}
        </TableCell>
        <TableCell align="left">{row.qty}</TableCell>
        <TableCell align="left">{row.filled_qty}</TableCell>
        <TableCell align="left">
          {row.filled_avg_price ? row.filled_avg_price : ""}
        </TableCell>
        <TableCell align="left">{row.filledAmt ? row.filledAmt : ""}</TableCell>
      </TableRow>
      {row.legs && row.legs.length !== 0 && (
        <ChildRow childRows={row.legs} open={open} />
      )}
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  const { rows, isOpenOrders } = props;

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    setPage(0);
  }, [isOpenOrders]);

  return (
    <>
      <TableContainer component={Paper} square variant="outlined">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {/*for drop down icon */}
              <TableCell padding="none" />
              {/*for P/L icon */}
              {!isOpenOrders && rows.length > 0 && <TableCell padding="none" />}
              <TableCell align="left">Submit Date</TableCell>
              <TableCell align="left">Symbol</TableCell>
              <TableCell align="left">Order Class</TableCell>
              <TableCell align="left">Side</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Order Qty</TableCell>
              <TableCell align="left">Fill Qty</TableCell>
              <TableCell align="left">Fill Price</TableCell>
              <TableCell align="left">Fill Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length !== 0 ? (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <Row key={row.id} row={row} isOpenOrders={isOpenOrders} />
                ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={10}
                  style={{ height: "100px" }}
                  align="center"
                >
                  <Typography variant="subtitle1" gutterBottom component="div">
                    No {isOpenOrders ? "Open" : "Closed"} Orders
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rows && rows.length !== 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}
