import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Button, ThemeProvider } from "@material-ui/core";
import Amplify, { Auth, Hub } from "aws-amplify";
import Copyright from "./Copyright";
import Signout from "./Signout";
import Theme, { useStyles } from "./Theme";
import history from "../history";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import awsConfig from "../config/aws-config-all-env";

Amplify.configure(awsConfig[process.env.REACT_APP_ENV_TYPE]);

export default function Homepage() {
  const theme = Theme();
  const classes = useStyles();
  const [user, setUser] = useState(null);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch((e) => console.error("Not signed in:", e));
  }

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    getUser().then((userData) => setUser(userData));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          {user && <Signout />}
          {!user && (
            <img src="logo3.png" alt="logo" className={classes.logoHome} />
          )}
          <p></p>
          <p></p>
          <p></p>
          <Typography component="h1" variant="h4">
            Welcome to NeXus
          </Typography>
          {user ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => history.push("/dashboard")}
              endIcon={<ArrowForwardIosIcon />}
            >
              Go to dashboard
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => Auth.federatedSignIn()}
            >
              Login to Start Trading
            </Button>
          )}
        </div>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
