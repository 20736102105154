import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Button } from "@material-ui/core";
import { Auth } from "aws-amplify";
import Copyright from "./Copyright";
import Signout from "./Signout";
import Theme, { useStyles } from "./Theme";
import { ThemeProvider } from "@material-ui/styles";
import {
  getUserTradeData,
  retrieveOrderStatistics,
  retrieveDailyStatistics,
  retrieveAlpacaOrders,
  updateUserTradeData,
} from "../api/UserTradeConfigAPI";
import history from "../history";
import AlpacaDetails from "./AlpacaDetail";
import { fetchAlpacaAccount } from "../api/AlpacaAPI";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { getCurrentLocalDateTimeStr } from "../utils/DateUtils";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RefreshIcon from "@material-ui/icons/Refresh";

export default function Dashboard() {
  const classes = useStyles();
  const theme = Theme();
  const [user, setUser] = useState(null);
  const [userTradeConfig, setUserTradeConfig] = useState(null);
  const [alpacaAccount, setAlpacaAccount] = useState(null);
  const [alpacaConfig, setAlpacaConfig] = useState(null);
  const [portfolioHistory, setPortfolioHistory] = useState(null);
  const [alpacaOpenOrders, setAlpacaOpenOrders] = useState([]);
  const [alpacaClosedOrders, setAlpacaClosedOrders] = useState([]);
  const [alpacaAccountError, setAlpacaAccountError] = useState(false);
  const [orderStatistics, setOrderStatistics] = useState({});
  const [resetStatsFlag, setResetStatsFlag] = useState(null);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => setUser(userData))
      .catch(() => history.push("/"));
  }

  useEffect(() => {
    console.log("inside user useEffect");
    if (user) {
      console.log("getting trade data");
      getUserTradeData(user.username)
        .then((e) => {
          if (e.length > 0) setUserTradeConfig(e);
          else {
            console.log("no records found for ", user.username);
            setAlpacaConfig("");
          }
        })
        .catch((e) => {
          console.error(e);
          setAlpacaConfig("");
        });
    } else {
      //console.log("getting user from auth");
      getUser();
    }
  }, [user]);

  useEffect(() => {
    //console.log("inside userTradeConfig useEffect", userTradeConfig);
    if (userTradeConfig) {
      let conf = userTradeConfig.find((x) => x.platformName === "alpaca");
      if (conf) {
        //console.log("User Trade Config Updated. Alpaca Config:", conf);
        //console.log("trade config:" , conf);
        setAlpacaConfig(conf);
      } else {
        setAlpacaConfig("");
      }
      retrieveOrderStatistics(
        userTradeConfig[0].userId,
        userTradeConfig[0].resetTimestamp
      )
        .then((data) => {
          if (data === null) {
            data = {};
            data.totalOrderCount = "NA";
            data.lossOrderCount = "NA";
            data.profitOrderCount = "NA";
          }
          setOrderStatistics(data);
        })
        .catch((e) => {
          console.error(e);
          let data = {};
          data.totalOrderCount = "NA";
          data.lossOrderCount = "NA";
          data.profitOrderCount = "NA";
          setOrderStatistics(data);
        });

      retrieveDailyStatistics(
        userTradeConfig[0].userId,
        userTradeConfig[0].resetTimestamp
      )
        .then((data) => {
          let portfolioHist = {};
          if (data === null) {
            portfolioHist.profit_loss = [];
            portfolioHist.profit_loss_pct = [];
          }
          portfolioHist.profit_loss = [data.dailyReturn];
          portfolioHist.profit_loss_pct = [data.dailyReturnPct];
          setPortfolioHistory(portfolioHist);
        })
        .catch((e) => {
          console.error(e);
          let portfolioHist = {};
          portfolioHist.profit_loss = [];
          portfolioHist.profit_loss_pct = [];
          setPortfolioHistory(portfolioHist);
        });

      retrieveAlpacaOrders(
        userTradeConfig[0].userId,
        userTradeConfig[0].resetTimestamp
      )
        .then((data) => {
          setAlpacaOpenOrders(data.openOrders);
          setAlpacaClosedOrders(data.closedOrders);
        })
        .catch((e) => {
          console.error(e);
          setAlpacaOpenOrders([]);
          setAlpacaClosedOrders([]);
        });
    }
  }, [userTradeConfig]);

  useEffect(() => {
    if (alpacaConfig && alpacaConfig !== "") {
      //console.log("Inside alpaca config useEffect - accesskey:" + accessKey + " secret:" + accessSecret);
      fetchAlpacaAccount()
        .then((accountResponse) => {
          setAlpacaAccount(accountResponse);
        })
        .catch((e) => {
          console.error(e);
          setAlpacaAccountError(true);
        });
    } else if (alpacaConfig && alpacaConfig === "") {
      setAlpacaAccount("");
    }
  }, [alpacaConfig]);

  function handleConfigRedirect() {
    if (alpacaConfig) {
      //console.log("Redirect", alpacaConfig);
      history.push({ pathname: "/config", state: { detail: alpacaConfig } });
    } else history.push("/config");
  }

  async function handleResetStats() {
    let tempUTConfList = [...userTradeConfig];
    let tempUTConf = tempUTConfList[0];
    tempUTConf.resetTimestamp = new Date().toISOString();
    //console.log(tempUTConf);
    let response = await updateUserTradeData(tempUTConf);
    if (response !== null) {
      setResetStatsFlag(true);
      setUserTradeConfig(tempUTConfList);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          {user ? <Signout /> : <></>}
          <p></p>
          <Typography component="h1" variant="h5">
            Welcome {user ? user.attributes.given_name : <></>} <p />
          </Typography>
          <Grid container spacing={6}>
            <Grid item xs>
              {alpacaAccount && alpacaAccount !== "" ? (
                <>
                  <Typography variant="subtitle2">
                    <b>Current Time:</b> {getCurrentLocalDateTimeStr()}
                  </Typography>
                  <Typography variant="subtitle2">
                    <b>Alpaca Account Cash:</b> ${alpacaAccount.cash}
                    <br />
                  </Typography>
                  <Typography variant="subtitle2">
                    <b>Alpaca Equity:</b> ${alpacaAccount.equity}
                    <br />
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Grid>

            <Grid item xs>
              {portfolioHistory && alpacaAccount && alpacaAccount && (
                <>
                  {portfolioHistory.profit_loss.length === 0 ||
                  portfolioHistory.profit_loss[0] === null ? (
                    <>
                      <Typography variant="body2" display="inline">
                        <b>Daily Return:</b> Currently Unavailable
                      </Typography>
                      <Typography variant="subtitle2">
                        <b>Daily Return %:</b> Currently Unavailable
                        <br />
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="subtitle2" display="inline">
                        <b>Daily Return: </b>
                      </Typography>
                      {portfolioHistory.profit_loss[0] > 0 ? (
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#008000" }}
                          display="inline"
                        >
                          ${portfolioHistory.profit_loss[0]}
                          <br />
                        </Typography>
                      ) : portfolioHistory.profit_loss[0] < 0 ? (
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#FF0000" }}
                          display="inline"
                        >
                          ${portfolioHistory.profit_loss[0]}
                          <br />
                        </Typography>
                      ) : (
                        <Typography variant="subtitle2" display="inline">
                          ${portfolioHistory.profit_loss[0]}
                          <br />
                        </Typography>
                      )}
                      <Typography variant="subtitle2" display="inline">
                        <b>Daily Return %: </b>
                      </Typography>
                      {portfolioHistory.profit_loss_pct[0] > 0 ? (
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#008000" }}
                          display="inline"
                        >
                          {portfolioHistory.profit_loss_pct[0]}
                          %<br />
                        </Typography>
                      ) : portfolioHistory.profit_loss_pct[0] < 0 ? (
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#FF0000" }}
                          display="inline"
                        >
                          {portfolioHistory.profit_loss_pct[0]}
                          %<br />
                        </Typography>
                      ) : (
                        <Typography variant="subtitle2" display="inline">
                          {portfolioHistory.profit_loss_pct[0]}
                          %<br />
                        </Typography>
                      )}
                    </>
                  )}
                  <Typography variant="subtitle2" display="inline">
                    <b>Total Trades (P/L):</b> {orderStatistics.totalOrderCount}{" "}
                    (
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ color: "#008000" }}
                    display="inline"
                  >
                    {orderStatistics.profitOrderCount}
                  </Typography>
                  <Typography variant="subtitle2" display="inline">
                    /
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ color: "#FF0000" }}
                    display="inline"
                  >
                    {orderStatistics.lossOrderCount}
                  </Typography>
                  <Typography variant="subtitle2" display="inline">
                    )
                  </Typography>
                </>
              )}
              <p />
            </Grid>

            <Grid item xs align="right">
              {alpacaAccount !== null && alpacaAccount !== "" && (
                <form>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleConfigRedirect}
                    startIcon={<AddCircleIcon />}
                  >
                    Configure Broker
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleResetStats}
                    startIcon={<RefreshIcon />}
                  >
                    Reset Statistics
                  </Button>
                  {resetStatsFlag === true && (
                    <Grid item xs={12}>
                      <Typography
                        component="h4"
                        variant="subtitle2"
                        align="right"
                        style={{ color: "#32CD32" }}
                      >
                        Stats Reset Successfully
                      </Typography>
                    </Grid>
                  )}
                </form>
              )}
            </Grid>
          </Grid>
          {alpacaAccount && alpacaAccount !== "" ? (
            <AlpacaDetails
              alpacaOpenOrders={alpacaOpenOrders}
              alpacaClosedOrders={alpacaClosedOrders}
            />
          ) : alpacaConfig === "" ? (
            <>
              <form>
                <Button
                  xs={6}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleConfigRedirect}
                  startIcon={<AddCircleIcon />}
                >
                  Configure Broker
                </Button>
              </form>
              <p></p>
              <Typography component="h2" variant="h6">
                Alpaca trading has not been enabled
              </Typography>
            </>
          ) : alpacaConfig && alpacaConfig !== "" && alpacaAccountError ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} align="center">
                  <form>
                    <Button
                      xs={6}
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleConfigRedirect}
                      startIcon={<AddCircleIcon />}
                    >
                      Re-configure Broker
                    </Button>
                  </form>
                </Grid>
              </Grid>
              <p></p>
              <Typography component="h3" variant="h6">
                Alpaca account key or secret is invalid. Please reconfigure your
                account.
              </Typography>
            </>
          ) : alpacaConfig === null ? (
            <div>
              <p></p>
              <CircularProgress />
            </div>
          ) : (
            <></>
          )}
          <p></p>
        </div>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
