import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Copyright from "./Copyright";
import Signout from "./Signout";
import { ThemeProvider } from "@material-ui/styles";
import Theme, { useStyles } from "./Theme";
import history from "../history";
import { withRouter, useLocation } from "react-router-dom";
import { verifyAplacaNewCreds } from "../api/AlpacaAPI";
import {
  setUserTradeData,
  updateUserTradeData,
} from "../api/UserTradeConfigAPI";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SaveIcon from "@material-ui/icons/Save";
import SettingsIcon from "@material-ui/icons/Settings";

const signalTypes = [
  {
    value: "momentum",
    label: "Momentum Alert",
  },
  {
    value: "setUp",
    label: "Algorithm Recommended Set-up",
  },
  {
    value: "*",
    label: "All",
  },
];

const financeOptions = [
  {
    value: "percentageAmt",
    label: "Invest a percentage of available balance",
  },
  {
    value: "setAmt",
    label: "Invest a set amount",
  },
];

const autoCloseOptions = [
  {
    value: "0",
    label: "Do not sell",
  },
  {
    value: "1",
    label: "Sell only at a Profit",
  },
  {
    value: "2",
    label: "Sell at Profit or within loss margin",
  },
];

const tradeOptions = [
  {
    value: "tpSlfromSignal",
    label: "Use signal take profit and stop loss limits (*recommended)",
  },
  {
    value: "noTpSl",
    label: "Do not set take profit and stop loss limits",
  },
  {
    value: "customTpSl",
    label: "I will set custom take profit and stop loss limits",
  },
];

/* const tradeCountOptions = [
  {
    value: "*",
    label: "No limit on number of trades",
  },
  {
    value: "day",
    label: "Enter total number of trades to be executed in a day",
  },
  {
    value: "symbol",
    label: "Enter number of trades to be executed per symbol in a day",
  },
]; */

const accountVerificationOptions = {
  present: "Alpaca keys present",
  verified:
    "Alpaca keys successfully verified. Click 'Activate Alpaca Trading' to save the new keys.",
  failed:
    "Alpaca keys Verification Failed. Please re-enter correct api key and secret and try again.",
  reverify: "Please re-verify api key and secret to proceed.",
};

const submitOptions = {
  success:
    "Broker trading configurations have been saved. NeXus will start placing orders based on your configuration. Head over to dashboard to view orders and balance.",
  failed:
    "Falied to save Alpaca configurations. Please complete the form before submitting.",
  errors: "Plese resolve any errors on the screen before submitting",
  disabled: "Trading is disabled",
};

function AlpacaTradeConfig() {
  const theme = Theme();
  const classes = useStyles();
  const location = useLocation();

  const [apiKey, setAPIKey] = useState("");
  const [apiSecret, setAPISecret] = useState("");
  const [showSecret, setShowSecret] = useState(false);
  const [signalType, setSignalType] = useState("");
  const [financeOption, setFinanceOption] = useState("");
  const [percentageAmount, setPercentageAmount] = useState(0);
  const [setAmount, setSetAmount] = useState(0);
  const [momentumCount, setMomentumCount] = useState(0);
  const [tradeOption, setTradeOption] = useState("");
  const [tp, setTp] = useState(0);
  const [sl, setSl] = useState(0);
  //const [tradeCountOption, setTradeCountOption] = useState("");
  //const [tradeCount, setTradeCount] = useState(0);
  const [autoCloseOption, setAutoCloseOption] = useState("");
  const [autoCloseLossMargin, setAutoCloseLossMargin] = useState(0);
  const [slippage, setSlippage] = useState(1);
  const [errors, setErrors] = useState({});
  const [alpacaConfig, setAlpacaConfig] = useState(null);

  const [active, setActive] = useState(true);
  const [username, setUsername] = useState("");
  const [accountVerification, setAccountVerification] = useState("");
  const [submitOutcome, setSubmitOutcome] = useState("");
  const [newAlpacaConfig, setNewAlpacaConfig] = useState(null);
  //const [riskRating, setRiskRating] = useState([]);
  const [tradeCountforRiskRed, setTradeCountforRiskRed] = useState(0);
  const [tradeCountforRiskOrange, setTradeCountforRiskOrange] = useState(-1);
  const [tradeCountforRiskGreen, setTradeCountforRiskGreen] = useState(-1);
  const [tradeCountforRiskBlue, setTradeCountforRiskBlue] = useState(-1);

  const useSimulator = true;

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((e) => setUsername(e.username))
      .catch(() => history.push("/"));
  }

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    console.log("Inside location execution");
    if (location.state && location.state.detail) {
      let alpacaConf = location.state.detail;
      setAlpacaConfig(alpacaConf);

      alpacaConf.accessKey && setAPIKey(alpacaConf.accessKey);
      alpacaConf.accessSecret && setAPISecret(alpacaConf.accessSecret);
      alpacaConf.active !== null && setActive(alpacaConf.active);
      setAccountVerification(accountVerificationOptions.present);
      alpacaConf.signalConfig &&
        alpacaConf.signalConfig.signalType &&
        setSignalType(alpacaConf.signalConfig.signalType);

      /* alpacaConf.signalConfig &&
        alpacaConf.signalConfig.riskRating &&
        setRiskRating(alpacaConf.signalConfig.riskRating);
 */
      alpacaConf.signalConfig.momentumCount &&
        setMomentumCount(alpacaConf.signalConfig.momentumCount);
      if (
        alpacaConf.financeConfig &&
        alpacaConf.financeConfig.percentageAmount > 0
      ) {
        setFinanceOption("percentageAmt");
        setPercentageAmount(alpacaConf.financeConfig.percentageAmount);
      } else if (
        alpacaConf.financeConfig &&
        alpacaConf.financeConfig.setAmount > 0
      ) {
        setFinanceOption("setAmt");
        setSetAmount(alpacaConf.financeConfig.setAmount);
      }
      if (alpacaConf.tradeConfig && alpacaConf.tradeConfig.customPercent) {
        setTradeOption("customTpSl");
        setTp(alpacaConf.tradeConfig.customPercent.tp);
        setSl(alpacaConf.tradeConfig.customPercent.sl);
      } else if (
        alpacaConf.tradeConfig &&
        alpacaConf.tradeConfig.tpSlFromSignal
      ) {
        setTradeOption("tpSlfromSignal");
      } else if (alpacaConf.tradeConfig && alpacaConf.tradeConfig.noTpSl) {
        setTradeOption("noTpSl");
      }
      /* if (alpacaConf.tradeConfig && alpacaConf.tradeConfig.tradeCount) {
        setTradeCountOption(alpacaConf.tradeConfig.tradeCount.unit);
        (alpacaConf.tradeConfig.tradeCount.unit === "day" ||
          alpacaConf.tradeConfig.tradeCount.unit === "symbol") &&
          setTradeCount(alpacaConf.tradeConfig.tradeCount.value);
      } */

      if (alpacaConf.tradeConfig.tradeCount) {
        alpacaConf.tradeConfig.tradeCount["1"]
          ? setTradeCountforRiskRed(alpacaConf.tradeConfig.tradeCount["1"])
          : setTradeCountforRiskRed(0);
        alpacaConf.tradeConfig.tradeCount["2"]
          ? setTradeCountforRiskOrange(alpacaConf.tradeConfig.tradeCount["2"])
          : setTradeCountforRiskOrange(0);
        alpacaConf.tradeConfig.tradeCount["3"]
          ? setTradeCountforRiskGreen(alpacaConf.tradeConfig.tradeCount["3"])
          : setTradeCountforRiskGreen(0);
        alpacaConf.tradeConfig.tradeCount["4"]
          ? setTradeCountforRiskBlue(alpacaConf.tradeConfig.tradeCount["4"])
          : setTradeCountforRiskBlue(0);
      }

      if (
        alpacaConf.tradeConfig.autoClose &&
        alpacaConf.tradeConfig.autoClose.margin > 0
      ) {
        setAutoCloseOption("2");
        setAutoCloseLossMargin(alpacaConf.tradeConfig.autoClose.margin);
      } else if (
        alpacaConf.tradeConfig.autoClose &&
        alpacaConf.tradeConfig.autoClose.margin === 0
      ) {
        setAutoCloseOption("1");
        setAutoCloseLossMargin(0);
      } else {
        setAutoCloseOption("0");
        setAutoCloseLossMargin(-1);
      }
      alpacaConf.tradeConfig &&
        alpacaConf.tradeConfig.slippagePercent &&
        setSlippage(alpacaConf.tradeConfig.slippagePercent);
    }
  }, [location]);

  useEffect(() => {
    //console.log("submit outcome", submitOutcome);
    //console.log("submit alpacaConfig", alpacaConfig);
    if (newAlpacaConfig) {
      if (submitOutcome === submitOptions.errors) {
        console.log("errors on post / put");
      } else if (submitOutcome === submitOptions.success) {
        console.log("success");
      } else if (submitOutcome === submitOptions.disabled) {
        console.log("disabled");
      } else if (submitOutcome === submitOptions.failed) {
        console.log("failed");
      } else {
        if (alpacaConfig !== null) {
          console.log("put");
          updateUserTradeData(newAlpacaConfig)
            .then(() => {
              setSubmitOutcome(submitOptions.success);
              setAlpacaConfig(newAlpacaConfig);
            })
            .catch((e) => {
              console.error(e);
              setSubmitOutcome(submitOptions.failed);
            });
        } else {
          console.log("post");
          setUserTradeData(newAlpacaConfig)
            .then(() => {
              //console.log(e);
              setSubmitOutcome(submitOptions.success);
              setAlpacaConfig(newAlpacaConfig);
            })
            .catch((e) => {
              console.error(e);
              setSubmitOutcome(submitOptions.failed);
            });
        }
      }
    }
  }, [alpacaConfig, newAlpacaConfig, submitOutcome]);

  useEffect(() => {
    console.log("inside signalType and tradeOption");
    if (tradeOption === "tpSlfromSignal" || tradeOption === "noTpSl") {
      setTp(1);
      setSl(1);
    }
    if (signalType === "setUp") {
      setMomentumCount(1);
    }
  }, [signalType, tradeOption]);

  useEffect(() => {
    console.log("inside financeOption");
    if (financeOption === "percentageAmt") {
      setSetAmount(1);
    } else if (financeOption === "setAmt") {
      setPercentageAmount(1);
    }
  }, [financeOption]);

  /* useEffect(() => {
    console.log("inside tradeCountOption");
    if (tradeCountOption === "*") {
      setTradeCount(0);
    }
  }, [tradeCountOption]);
 */
  useEffect(() => {
    console.log("inside autoCloseOption");
    if (autoCloseOption === "0") {
      setAutoCloseLossMargin(0);
    } else if (autoCloseOption === "1") {
      setAutoCloseLossMargin(0);
    }
  }, [autoCloseOption]);

  const handleClickShowSecret = () => {
    setShowSecret(!showSecret);
  };

  const handleChange = (e) => {
    console.log("inside handle change");
    const { name, value } = e.target;

    switch (name) {
      case "apiKey":
        setAPIKey(value);
        validate(name, value);
        setAccountVerification(accountVerificationOptions.reverify);
        setSubmitOutcome(null);
        break;
      case "apiSecret":
        setAPISecret(value);
        validate(name, value);
        setAccountVerification(accountVerificationOptions.reverify);
        setSubmitOutcome(null);
        break;
      case "signalType":
        setSignalType(value);
        validate(name, value);
        break;
      case "financeOption":
        setFinanceOption(value);
        validate(name, value);
        break;
      case "percentageAmount":
        setPercentageAmount(value);
        validate(name, value);
        break;
      case "setAmount":
        setSetAmount(value);
        validate(name, value);
        break;
      case "momentumCount":
        setMomentumCount(value);
        validate(name, value);
        break;
      case "tradeOption":
        setTradeOption(value);
        validate(name, value);
        break;
      case "tp":
        setTp(value);
        validate(name, value);
        break;
      case "sl":
        setSl(value);
        validate(name, value);
        break;
      /* case "tradeCountOption":
        setTradeCountOption(value);
        validate(name, value);
        break;
      case "tradeCount":
        setTradeCount(value);
        validate(name, value);
        break; */
      case "tradeCountforRiskRed":
        setTradeCountforRiskRed(value);
        validate(name, value);
        break;
      case "tradeCountforRiskOrange":
        setTradeCountforRiskOrange(value);
        validate(name, value);
        break;
      case "tradeCountforRiskGreen":
        setTradeCountforRiskGreen(value);
        validate(name, value);
        break;
      case "tradeCountforRiskBlue":
        setTradeCountforRiskBlue(value);
        validate(name, value);
        break;
      case "autoCloseOption":
        setAutoCloseOption(value);
        validate(name, value);
        break;
      case "autoCloseLossMargin":
        setAutoCloseLossMargin(value);
        validate(name, value);
        break;
      case "slippage":
        setSlippage(value);
        validate(name, value);
        break;
      /* case "riskRating":
        setRiskRating(value);
        break; */
      default:
    }
  };

  const validate = (name, value) => {
    let temp = { ...errors };

    if ("apiKey" === name) {
      value ? delete temp.apiKey : (temp.apiKey = "This field is required.");
    }
    if ("apiSecret" === name) {
      value
        ? delete temp.apiSecret
        : (temp.apiSecret = "This field is required.");
    }
    if ("signalType" === name) {
      value
        ? delete temp.signalType
        : (temp.signalType = "This field is required.");
    }
    if ("financeOption" === name) {
      value
        ? delete temp.financeOption
        : (temp.financeOption = "This field is required.");
    }
    if ("percentageAmount" === name) {
      value >= 1 && value <= 100
        ? delete temp.percentageAmount
        : (temp.percentageAmount = "Percentage must be a number.");
    }
    if ("setAmount" === name) {
      value >= 1
        ? delete temp.setAmount
        : (temp.setAmount = "Amount must be a number.");
    }
    if ("momentumCount" === name) {
      value >= 1 || value === ""
        ? delete temp.momentumCount
        : (temp.momentumCount = "Count must be blank or a number.");
    }
    if ("tradeOption" === name) {
      value
        ? delete temp.tradeOption
        : (temp.tradeOption = "This field is required.");
    }
    if ("tp" === name) {
      value >= 1 && value <= 100
        ? delete temp.tp
        : (temp.tp = "Trade Profit percentage must be a number.");
    }
    if ("sl" === name) {
      value >= 1 && value <= 100
        ? delete temp.sl
        : (temp.sl = "Stop Loss percentage must be a number.");
    }
    /*  if ("tradeCountOption" === name) {
      value
        ? delete temp.tradeCountOption
        : (temp.tradeCountOption = "This field is required.");
    }
    if ("tradeCount" === name) {
      Number.isInteger(Number.parseFloat(value)) && value >= 1
        ? delete temp.tradeCount
        : (temp.tradeCount = "Trade count must be a number.");
    } */

    if ("tradeCountforRiskRed" === name) {
      Number.isInteger(Number.parseFloat(value)) && value >= -1
        ? delete temp.tradeCountforRiskRed
        : (temp.tradeCountforRiskRed = "Trade count must be a positive number");
    }
    if ("tradeCountforRiskOrange" === name) {
      Number.isInteger(Number.parseFloat(value)) && value >= -1
        ? delete temp.tradeCountforRiskOrange
        : (temp.tradeCountforRiskOrange =
            "Trade count must be a positive number");
    }
    if ("tradeCountforRiskGreen" === name) {
      Number.isInteger(Number.parseFloat(value)) && value >= -1
        ? delete temp.tradeCountforRiskGreen
        : (temp.tradeCountforRiskGreen =
            "Trade count must be a positive number");
    }
    if ("tradeCountforRiskBlue" === name) {
      Number.isInteger(Number.parseFloat(value)) && value >= -1
        ? delete temp.tradeCountforRiskBlue
        : (temp.tradeCountforRiskBlue =
            "Trade count must be a positive number");
    }
    if ("autoCloseOption" === name) {
      value
        ? delete temp.autoCloseOption
        : (temp.autoCloseOption = "This field is required.");
    }
    if ("autoCloseLossMargin" === name) {
      Number.isInteger(Number.parseFloat(value)) && value >= 0
        ? delete temp.autoCloseLossMargin
        : (temp.autoCloseLossMargin =
            "Auto close loss margin must be a number.");
    }
    if ("slippage" === name) {
      value >= 0.1 && value <= 2
        ? delete temp.slippage
        : (temp.slippage =
            "Slippage peprcentage must be between 0.1 and 2 percent.");
    }
    setErrors({ ...temp });
    console.log(JSON.stringify(temp));
  };

  const handleSubmit = (e) => {
    let submitError = false;
    let newAlpacaConfig = {};
    newAlpacaConfig.accessKey = apiKey;
    newAlpacaConfig.accessSecret = apiSecret;
    newAlpacaConfig.useSimulator = useSimulator;
    newAlpacaConfig.active = active;
    newAlpacaConfig.signalConfig = {};
    //newAlpacaConfig.signalConfig.riskRating = Number(riskRating);
    newAlpacaConfig.signalConfig.signalType = signalType;
    if (signalType === "momentum" || signalType === "*") {
      if (errors.momentumCount) submitError = true;
      else newAlpacaConfig.signalConfig.momentumCount = parseInt(momentumCount);
    }

    newAlpacaConfig.financeConfig = {};
    if (financeOption === "percentageAmt") {
      if (errors.percentageAmount) submitError = true;
      else
        newAlpacaConfig.financeConfig.percentageAmount =
          Number(percentageAmount);
    } else if (financeOption === "setAmt") {
      if (errors.setAmount) submitError = true;
      else newAlpacaConfig.financeConfig.setAmount = Number(setAmount);
    }

    newAlpacaConfig.tradeConfig = {};
    newAlpacaConfig.tradeConfig.tradeCount = {};
    newAlpacaConfig.tradeConfig.tradeCount[1] = Number(tradeCountforRiskRed);
    newAlpacaConfig.tradeConfig.tradeCount[2] = Number(tradeCountforRiskOrange);
    newAlpacaConfig.tradeConfig.tradeCount[3] = Number(tradeCountforRiskGreen);
    newAlpacaConfig.tradeConfig.tradeCount[4] = Number(tradeCountforRiskBlue);
    if (tradeOption === "customTpSl") {
      if (errors.tp || errors.sl) submitError = true;
      else {
        newAlpacaConfig.tradeConfig.customPercent = {};
        newAlpacaConfig.tradeConfig.customPercent.tp = Number(tp);
        newAlpacaConfig.tradeConfig.customPercent.sl = Number(sl);
      }
    } else if (tradeOption === "noTpSl") {
      newAlpacaConfig.tradeConfig.noTpSl = true;
    } else if (tradeOption === "tpSlfromSignal") {
      newAlpacaConfig.tradeConfig.tpSlFromSignal = true;
    }
    /* if (["*", "day", "symbol"].includes(tradeCountOption)) {
      newAlpacaConfig.tradeConfig.tradeCount = {};
      newAlpacaConfig.tradeConfig.tradeCount.unit = tradeCountOption;
      if (errors.tradeCount) submitError = true;
      else {
        if (tradeCountOption !== "*")
          newAlpacaConfig.tradeConfig.tradeCount.value = Number(tradeCount);
      }
    } */
    if (["1", "2"].includes(autoCloseOption)) {
      newAlpacaConfig.tradeConfig.autoClose = {};
      newAlpacaConfig.tradeConfig.autoClose.margin =
        Number(autoCloseLossMargin);
    }

    errors.slippage
      ? (submitError = true)
      : (newAlpacaConfig.tradeConfig.slippagePercent = Number(slippage));

    //console.log("handle submit alpaca config", alpacaConfig);
    if (alpacaConfig !== null) {
      newAlpacaConfig.userId = alpacaConfig.userId;
      newAlpacaConfig.platformName = alpacaConfig.platformName;
      newAlpacaConfig.resetTimestamp = alpacaConfig.resetTimestamp;
    } else {
      newAlpacaConfig.userId = username;
      newAlpacaConfig.platformName = "alpaca";
    }

    //console.log("New Alpaca Config:" , newAlpacaConfig)

    console.log("submit error", submitError);
    submitError ? setSubmitOutcome(submitOptions.errors) : setSubmitOutcome("");
    setNewAlpacaConfig(newAlpacaConfig);
    e.preventDefault();
  };

  function verifyAlpaca() {
    if (apiKey && apiSecret) {
      //console.log("Verifying alpaca account - accesskey:" + apiKey + " secret:" + apiSecret);
      verifyAplacaNewCreds(apiKey, apiSecret)
        .then(() => {
          //console.log("accountResponse:", accountResponse);
          setAccountVerification(accountVerificationOptions.verified);
        })
        .catch((e) => {
          console.error(e);
          setAccountVerification(accountVerificationOptions.failed);
          setSubmitOutcome(null);
        });
    } else console.log("key and/or secret are not set");
  }

  const handleSwitch = (event) => {
    //console.log("inside handle switch");
    setActive(event.target.checked);
    //console.log("update account activity - active:", event.target.checked);
    alpacaConfig.active = event.target.checked;
    //console.log("alpaca config inside handle check", alpacaConfig);
    updateUserTradeData(alpacaConfig)
      .then((e) => {
        console.error(e);
        setAlpacaConfig(alpacaConfig);
        if (alpacaConfig.active) setSubmitOutcome(submitOptions.success);
        else setSubmitOutcome(submitOptions.disabled);
      })
      .catch((e) => {
        console.error(e);
        setSubmitOutcome(submitOptions.failed);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="md">
        <div className={classes.paper}>
          <Signout />
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                {(accountVerification === accountVerificationOptions.verified ||
                  accountVerification ===
                    accountVerificationOptions.present) && (
                  <Tooltip title="Set Active to enable automatic trading and Disable to turn off trading">
                    <Typography component="div">
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>Disable</Grid>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={active}
                                onChange={handleSwitch}
                                name="accountType"
                                color="primary"
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                      </Grid>
                      <p></p>
                    </Typography>
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={6} align="center">
                <Typography component="h1" variant="h5">
                  Alpaca Configuration
                </Typography>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={5}>
                <TextField
                  name="apiKey"
                  variant="outlined"
                  required
                  fullWidth
                  id="apiKey"
                  label="Alpaca API Key"
                  autoFocus
                  error={
                    errors.apiKey && errors.apiKey.value !== "" ? true : false
                  }
                  helperText={
                    errors.apiKey && errors.apiKey.value !== ""
                      ? "API key is mandatory"
                      : ""
                  }
                  value={apiKey}
                  onChange={handleChange}
                  onBlur={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {accountVerification ===
                        accountVerificationOptions.verified ? (
                          <CheckCircleRoundedIcon style={{ color: "32CD32" }} />
                        ) : accountVerification ===
                            accountVerificationOptions.failed ||
                          accountVerification ===
                            accountVerificationOptions.reverify ? (
                          <CancelIcon color="error" />
                        ) : (
                          <></>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  name="apiSecret"
                  variant="outlined"
                  required
                  fullWidth
                  id="apiSecret"
                  label="API Secret"
                  type={showSecret ? "text" : "password"}
                  value={apiSecret}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowSecret}
                        >
                          {showSecret ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        {accountVerification ===
                        accountVerificationOptions.verified ? (
                          <CheckCircleRoundedIcon
                            style={{ color: "#32CD32" }}
                          />
                        ) : accountVerification ===
                            accountVerificationOptions.failed ||
                          accountVerification ===
                            accountVerificationOptions.reverify ? (
                          <CancelIcon color="error" />
                        ) : (
                          <></>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  error={
                    errors.apiSecret && errors.apiSecret.value !== ""
                      ? true
                      : false
                  }
                  helperText={
                    errors.apiSecret && errors.apiSecret.value !== ""
                      ? "API secret is mandatory"
                      : ""
                  }
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  size="small"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={verifyAlpaca}
                  startIcon={<SettingsIcon />}
                >
                  Verify Account
                </Button>
              </Grid>
              {accountVerification === accountVerificationOptions.verified && (
                <Grid item xs={12}>
                  <Typography
                    component="h4"
                    variant="h6"
                    align="left"
                    style={{ color: "#32CD32" }}
                  >
                    {accountVerification}
                  </Typography>
                </Grid>
              )}
              {(accountVerification === accountVerificationOptions.failed ||
                accountVerification ===
                  accountVerificationOptions.reverify) && (
                <Grid item xs={12}>
                  <Typography
                    component="h4"
                    variant="h6"
                    align="left"
                    style={{ color: "#F90707" }}
                  >
                    {accountVerification}
                  </Typography>
                </Grid>
              )}
              {(accountVerification === accountVerificationOptions.present ||
                accountVerification ===
                  accountVerificationOptions.verified) && (
                <>
                  <Grid item xs={12} />
                  <Grid item xs={12}>
                    <Typography component="h2" variant="h6" align="center">
                      Trading Balance Configuration
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="financeOption"
                      variant="outlined"
                      required
                      fullWidth
                      id="financeOption"
                      label="Select a finance option"
                      select
                      value={financeOption}
                      onChange={handleChange}
                      onBlur={handleChange}
                      error={
                        errors.financeOption &&
                        errors.financeOption.value !== ""
                          ? true
                          : false
                      }
                      helperText={
                        errors.financeOption &&
                        errors.financeOption.value !== ""
                          ? "Finance option is mandatory"
                          : ""
                      }
                    >
                      {financeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    {financeOption === "percentageAmt" ? (
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="percentageAmount"
                        label="Set percentage of available balance to invest"
                        name="percentageAmount"
                        value={percentageAmount}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        onBlur={handleChange}
                        error={
                          errors.percentageAmount &&
                          errors.percentageAmount.value !== ""
                            ? true
                            : false
                        }
                        helperText={
                          errors.percentageAmount &&
                          errors.percentageAmount.value !== ""
                            ? "Percentage must be between 1% and 100%"
                            : ""
                        }
                      />
                    ) : financeOption === "setAmt" ? (
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="setAmount"
                        label="Set investment limit amount"
                        name="setAmount"
                        value={setAmount}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        onBlur={handleChange}
                        error={
                          errors.setAmount && errors.setAmount.value !== ""
                            ? true
                            : false
                        }
                        helperText={
                          errors.setAmount && errors.setAmount.value !== ""
                            ? "Amount must be between $1 and available account balance"
                            : ""
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="h3" variant="h6" align="center">
                      Trade Configuration and Risk Management
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="signalType"
                      variant="outlined"
                      required
                      fullWidth
                      id="signalType"
                      label="Select the type of signal to trade on"
                      select
                      value={signalType}
                      onChange={handleChange}
                      onBlur={handleChange}
                      error={
                        errors.signalType && errors.signalType.value !== ""
                          ? true
                          : false
                      }
                      helperText={
                        errors.signalType && errors.signalType.value !== ""
                          ? "Signal type is mandatory"
                          : ""
                      }
                    >
                      {signalTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {signalType === "momentum" || signalType === "*" ? (
                    <Grid item xs={12}>
                      <TextField
                        name="momentumCount"
                        variant="outlined"
                        required
                        fullWidth
                        id="momentumCount"
                        label="Set momentum count after which to place trades"
                        value={momentumCount}
                        onChange={handleChange}
                        onBlur={handleChange}
                        error={
                          errors.momentumCount &&
                          errors.momentumCount.value !== ""
                            ? true
                            : false
                        }
                        helperText={
                          errors.momentumCount &&
                          errors.momentumCount.value !== ""
                            ? "Momentum count must be 1 or greater than 1"
                            : ""
                        }
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      name="slippage"
                      variant="outlined"
                      required
                      fullWidth
                      id="slippage"
                      label="Set slippage percent of signal entry price"
                      value={slippage}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      onChange={handleChange}
                      onBlur={handleChange}
                      error={
                        errors.slippage && errors.slippage.value !== ""
                          ? true
                          : false
                      }
                      helperText={
                        errors.slippage && errors.slippage.value !== ""
                          ? "Slippage must be between 0.5% and 2.0%"
                          : ""
                      }
                    />
                  </Grid>
                  {/*  <Grid item xs={12}>
                    <TextField
                      name="riskRating"
                      variant="outlined"
                      required
                      fullWidth
                      id="riskRating"
                      label="Select a risk rating"
                      select
                      value={riskRating}
                      onChange={handleChange}
                      onBlur={handleChange}
                    >
                      <MenuItem key="1" value="1">
                        All risk ratings &emsp; &emsp; &emsp; &emsp;&emsp;
                        <FiberManualRecordIcon htmlColor="red" />
                        <FiberManualRecordIcon htmlColor="green" />
                        <FiberManualRecordIcon htmlColor="orange" />
                        <FiberManualRecordIcon htmlColor="blue" />
                      </MenuItem>
                      <MenuItem key="2" value="2">
                        Moderate or lower &emsp;
                        <FiberManualRecordIcon htmlColor="orange" />
                        <FiberManualRecordIcon htmlColor="green" />
                        <FiberManualRecordIcon htmlColor="blue" />
                      </MenuItem>
                      <MenuItem key="3" value="3">
                        Low or lower
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FiberManualRecordIcon htmlColor="green" />
                        <FiberManualRecordIcon htmlColor="blue" />
                      </MenuItem>
                      <MenuItem key="4" value="4">
                        Lowest risk only &emsp; &emsp; &emsp;
                        <FiberManualRecordIcon htmlColor="blue" />
                      </MenuItem>
                    </TextField>
                  </Grid> */}
                  <Grid item xs={12}>
                    <TextField
                      name="tradeOption"
                      variant="outlined"
                      required
                      fullWidth
                      id="tradeOption"
                      label="Select your trade option"
                      select
                      value={tradeOption}
                      onChange={handleChange}
                      onBlur={handleChange}
                      error={
                        errors.tradeOption && errors.tradeOption.value !== ""
                          ? true
                          : false
                      }
                      FormHelperTextProps={{ error: true }}
                      helperText={
                        errors.tradeOption && errors.tradeOption.value !== ""
                          ? "Trade option is mandatory"
                          : tradeOption === "noTpSl"
                          ? "Warning: You are not setting a Take Profit or Stop Loss, this trade will need to be closed or modified manually through your broker"
                          : ""
                      }
                    >
                      {tradeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {tradeOption === "tpSlfromSignal" ||
                  tradeOption === "noTpSl" ||
                  tradeOption === "" ? (
                    <></>
                  ) : (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="tp"
                          variant="outlined"
                          required
                          fullWidth
                          id="tp"
                          label="Set take profit percent of actual price"
                          value={tp}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={
                            errors.tp && errors.tp.value !== "" ? true : false
                          }
                          helperText={
                            errors.tp && errors.tp.value !== ""
                              ? "Trade profit % must be between 1% and 100%"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="sl"
                          variant="outlined"
                          required
                          fullWidth
                          id="sl"
                          label="Set stop loss percent of actual price"
                          value={sl}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={
                            errors.sl && errors.sl.value !== "" ? true : false
                          }
                          FormHelperTextProps={{ error: true }}
                          helperText={
                            errors.sl && errors.sl.value !== ""
                              ? "Stop loss % must be between 1% and 100%"
                              : sl > 10
                              ? "Warning: You are using a high % stop loss"
                              : ""
                          }
                        />
                      </Grid>
                    </>
                  )}

                  {/*    <Grid item xs={12}>
                    <TextField
                      name="tradeCountOption"
                      variant="outlined"
                      required
                      fullWidth
                      id="tradeCountOption"
                      label="Select your trade count option"
                      select
                      value={tradeCountOption}
                      onChange={handleChange}
                      onBlur={handleChange}
                      error={
                        errors.tradeCountOption &&
                        errors.tradeCountOption.value !== ""
                          ? true
                          : false
                      }
                      FormHelperTextProps={{ error: true }}
                      helperText={
                        errors.tradeCountOption &&
                        errors.tradeCountOption.value !== ""
                          ? "Trade count option is mandatory"
                          : ""
                      }
                    >
                      {tradeCountOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> */}
                  {/* tradeCountOption === "*" ? (
                    <></>
                  ) : (
                    <Grid item xs={12}>
                      <TextField
                        name="tradeCount"
                        variant="outlined"
                        required
                        fullWidth
                        id="tradeCount"
                        label="Set number of trades to be placed in the period"
                        value={tradeCount}
                        onChange={handleChange}
                        onBlur={handleChange}
                        error={
                          errors.tradeCount && errors.tradeCount.value !== ""
                            ? true
                            : false
                        }
                        helperText={
                          errors.tradeCount && errors.tradeCount.value !== ""
                            ? "Trade count must be a positive number"
                            : ""
                        }
                      />
                    </Grid>
                  ) */}

                  <Grid item xs={12}>
                    <TextField
                      name="autoCloseOption"
                      variant="outlined"
                      required
                      fullWidth
                      id="autoCloseOption"
                      label="Select your end-of-day auto close option"
                      select
                      value={autoCloseOption}
                      onChange={handleChange}
                      onBlur={handleChange}
                      error={
                        errors.autoCloseOption &&
                        errors.autoCloseOption.value !== ""
                          ? true
                          : false
                      }
                      FormHelperTextProps={{ error: true }}
                      helperText={
                        errors.autoCloseOption &&
                        errors.autoCloseOption.value !== ""
                          ? "End-of-day auto close option is mandatory"
                          : ""
                      }
                    >
                      {autoCloseOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {["0", "1"].includes(autoCloseOption) ? (
                    <></>
                  ) : (
                    <Grid item xs={12}>
                      <TextField
                        name="autoCloseLossMargin"
                        variant="outlined"
                        required
                        fullWidth
                        id="autoCloseLossMargin"
                        label="Set the acceptable loss margin in percentage for end-of-day auto close"
                        value={autoCloseLossMargin}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        onBlur={handleChange}
                        error={
                          errors.autoCloseLossMargin &&
                          errors.autoCloseLossMargin.value !== ""
                            ? true
                            : false
                        }
                        helperText={
                          errors.autoCloseLossMargin &&
                          errors.autoCloseLossMargin.value !== ""
                            ? "End-of-day auto close loss margin percentage must be between 1% and 100%"
                            : ""
                        }
                      />
                    </Grid>
                  )}

                  <Grid item xs={3}></Grid>
                  <Grid item xs={6} align="center">
                    <Typography component="h3" variant="h6">
                      Number of trades per risk level
                    </Typography>
                    <Typography variant="caption" display="block">
                      (-1 = no limit)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="tradeCountforRiskRed"
                      variant="outlined"
                      required
                      fullWidth
                      id="tradeCountforRiskRed"
                      label="Enter no. of trades for red risk rating signals"
                      value={tradeCountforRiskRed}
                      onChange={handleChange}
                      onBlur={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FiberManualRecordIcon style={{ color: "red" }} />
                          </InputAdornment>
                        ),
                      }}
                      error={
                        errors.tradeCountforRiskRed &&
                        errors.tradeCountforRiskRed.value !== ""
                          ? true
                          : false
                      }
                      helperText={
                        errors.tradeCountforRiskRed &&
                        errors.tradeCountforRiskRed.value !== ""
                          ? "No. of trades can be set to -1 for unlimited, 0 for no trades or a positive number"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="tradeCountforRiskOrange"
                      variant="outlined"
                      required
                      fullWidth
                      id="tradeCountforRiskOrange"
                      label="Enter no. of trades for orange risk rating signals"
                      value={tradeCountforRiskOrange}
                      onChange={handleChange}
                      onBlur={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FiberManualRecordIcon
                              style={{ color: "orange" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      error={
                        errors.tradeCountforRiskOrange &&
                        errors.tradeCountforRiskOrange.value !== ""
                          ? true
                          : false
                      }
                      helperText={
                        errors.tradeCountforRiskOrange &&
                        errors.tradeCountforRiskOrange.value !== ""
                          ? "No. of trades can be set to -1 for unlimited, 0 for no trades or a positive number"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="tradeCountforRiskGreen"
                      variant="outlined"
                      required
                      fullWidth
                      id="tradeCountforRiskGreen"
                      label="Enter no. of trades for green risk rating signals"
                      value={tradeCountforRiskGreen}
                      onChange={handleChange}
                      onBlur={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FiberManualRecordIcon style={{ color: "green" }} />
                          </InputAdornment>
                        ),
                      }}
                      error={
                        errors.tradeCountforRiskGreen &&
                        errors.tradeCountforRiskGreen.value !== ""
                          ? true
                          : false
                      }
                      helperText={
                        errors.tradeCountforRiskGreen &&
                        errors.tradeCountforRiskGreen.value !== ""
                          ? "No. of trades can be set to -1 for unlimited, 0 for no trades or a positive number"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="tradeCountforRiskBlue"
                      variant="outlined"
                      required
                      fullWidth
                      id="tradeCountforRiskBlue"
                      label="Enter no. of trades for blue risk rating signals"
                      value={tradeCountforRiskBlue}
                      onChange={handleChange}
                      onBlur={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FiberManualRecordIcon style={{ color: "blue" }} />
                          </InputAdornment>
                        ),
                      }}
                      error={
                        errors.tradeCountforRiskBlue &&
                        errors.tradeCountforRiskBlue.value !== ""
                          ? true
                          : false
                      }
                      helperText={
                        errors.tradeCountforRiskBlue &&
                        errors.tradeCountforRiskBlue.value !== ""
                          ? "No. of trades can be set to -1 for unlimited, 0 for no trades or a positive number"
                          : ""
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {(accountVerification === accountVerificationOptions.verified ||
              accountVerification === accountVerificationOptions.present) && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                startIcon={<SaveIcon />}
              >
                Activate Alpaca Trading
              </Button>
            )}
          </form>
          {submitOutcome === submitOptions.success && (
            <Grid item xs={12}>
              <Typography
                component="h4"
                variant="h6"
                align="left"
                style={{ color: "#32CD32" }}
              >
                {submitOutcome}
              </Typography>
            </Grid>
          )}
          {(submitOutcome === submitOptions.failed ||
            submitOutcome === submitOptions.errors ||
            submitOutcome === submitOptions.disabled) && (
            <Grid item xs={12}>
              <Typography
                component="h4"
                variant="h6"
                align="left"
                style={{ color: "#F90707" }}
              >
                {submitOutcome}
              </Typography>
            </Grid>
          )}
        </div>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
export default withRouter(AlpacaTradeConfig);
