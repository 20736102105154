import Amplify, { API, Auth } from "aws-amplify";

import awsConfig from "../config/aws-config-all-env";

Amplify.configure({
  API: awsConfig[process.env.REACT_APP_ENV_TYPE].apiGateway,
});
export async function verifyAplacaNewCreds(accessKey, accessSecret) {
  const [currentSession, authUser] = await Promise.all([
    Auth.currentSession(),
    Auth.currentUserInfo(),
  ]);

  let response = [];
  const init = {
    body: { accessKey, accessSecret },
    headers: {
      "x-lft-user": authUser.username,
      Authorization: `Bearer ${currentSession.getIdToken().getJwtToken()}`,
    },
  };

  try {
    response = await API.post("lft-app-api", "/alpaca-verify", init);
  } catch (err) {
    console.log("Post error", err);
    throw err;
  }
  return response;
}

export async function fetchAlpacaAccount() {
  const [currentSession, authUser] = await Promise.all([
    Auth.currentSession(),
    Auth.currentUserInfo(),
  ]);

  let response = [];
  try {
    response = await API.get("lft-app-api", "/alpaca-account", {
      headers: {
        "x-lft-user": authUser.username,
        Authorization: `Bearer ${currentSession.getIdToken().getJwtToken()}`,
      },
    });
  } catch (err) {
    console.log("Get error", err);
    throw err;
  }
  return response;
}
