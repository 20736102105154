/* eslint-disable */

const awsConfig = {
  local: {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_h9BNTXW2k",
    aws_user_pools_web_client_id: "30tu5k7st55r65mt4vkl05qf7v",
    oauth: {
      domain: "dev-auth.lowfloattrading.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "http://localhost:3000/dashboard",
      redirectSignOut: "https://localhost:3000/",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
    aws_cognito_login_mechanism: ["EMAIL", "PREFERRED_USERNAME"],
    aws_cognito_signup_attributes: ["EMAIL", "FAMILY_NAME", "GIVEN_NAME"],
    aws_cognito_mfa_configuration: "OPTIONAL",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        "REQUIRES_LOWERCASE",
        "REQUIRES_UPPERCASE",
        "REQUIRES_NUMBERS",
        "REQUIRES_SYMBOLS",
      ],
    },
    apiGateway: {
      endpoints: [
        {
          name: "lft-app-api",
          endpoint: "https://dev-nexus.lowfloattrading.com/api/v1",
        },
      ],
    },
  },
  dev: {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_h9BNTXW2k",
    aws_user_pools_web_client_id: "30tu5k7st55r65mt4vkl05qf7v",
    oauth: {
      domain: "dev-auth.lowfloattrading.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "https://dev-nexus.lowfloattrading.com/dashboard",
      redirectSignOut: "https://dev-nexus.lowfloattrading.com/",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
    aws_cognito_login_mechanism: ["EMAIL", "PREFERRED_USERNAME"],
    aws_cognito_signup_attributes: ["EMAIL", "FAMILY_NAME", "GIVEN_NAME"],
    aws_cognito_mfa_configuration: "OPTIONAL",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        "REQUIRES_LOWERCASE",
        "REQUIRES_UPPERCASE",
        "REQUIRES_NUMBERS",
        "REQUIRES_SYMBOLS",
      ],
    },
    apiGateway: {
      endpoints: [
        {
          name: "lft-app-api",
          endpoint: "/api/v1",
        },
      ],
    },
  },
  "prod-live": {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_mQOm9vAi0",
    aws_user_pools_web_client_id: "2kjutn4drd551v01tlm0aosi82",
    oauth: {
      domain: "auth.lowfloattrading.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "https://nexus.lowfloattrading.com/dashboard",
      redirectSignOut: "https://nexus.lowfloattrading.com/",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
    aws_cognito_login_mechanism: ["EMAIL", "PREFERRED_USERNAME"],
    aws_cognito_signup_attributes: ["EMAIL", "FAMILY_NAME", "GIVEN_NAME"],
    aws_cognito_mfa_configuration: "OPTIONAL",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        "REQUIRES_LOWERCASE",
        "REQUIRES_UPPERCASE",
        "REQUIRES_NUMBERS",
        "REQUIRES_SYMBOLS",
      ],
    },
    apiGateway: {
      endpoints: [
        {
          name: "lft-app-api",
          endpoint: "/api/v1",
        },
      ],
    },
  },
  "prod-paper": {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_mQOm9vAi0",
    aws_user_pools_web_client_id: "2kjutn4drd551v01tlm0aosi82",
    oauth: {
      domain: "auth.lowfloattrading.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "https://paper-nexus.lowfloattrading.com/dashboard",
      redirectSignOut: "https://paper-nexus.lowfloattrading.com/",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
    aws_cognito_login_mechanism: ["EMAIL", "PREFERRED_USERNAME"],
    aws_cognito_signup_attributes: ["EMAIL", "FAMILY_NAME", "GIVEN_NAME"],
    aws_cognito_mfa_configuration: "OPTIONAL",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        "REQUIRES_LOWERCASE",
        "REQUIRES_UPPERCASE",
        "REQUIRES_NUMBERS",
        "REQUIRES_SYMBOLS",
      ],
    },
    apiGateway: {
      endpoints: [
        {
          name: "lft-app-api",
          endpoint: "/api/v1",
        },
      ],
    },
  },
};

export default awsConfig;
