//

/* eslint react/no-unescaped-entities: 0 */

import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function Copyright() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Box mt={5}>
      <Typography variant="body2" color="textSecondary" align="center">
        {"By accessing this application, you agree to the"}
        <Button onClick={handleClickOpen("paper")}>
          Terms and Conditions.
        </Button>
        {"NeXus (Patent Pending) from "}
        <Link
          color="inherit"
          href="https://lowfloattrading.com/"
          rel="noopener"
          target="_blank"
        >
          LowFloatTrading.com
        </Link>
        {
          " is a copyrighted product of LowFloatTrading LLC Licensed and Registered in the State of Nevada, USA."
        }
      </Typography>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms of Use</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <b>Definitions</b>
            <br />
            "Account" means an online account registered by You for the purpose
            of using the Services.
            <br />
            "Additional Services" means Services available only through
            registration of an Account, as distinguished from the Basic Services
            which are generally available without such registration.
            LowFloatTrading may, at its discretion, decide that Basic Services
            which have heretofore been available without registration shall
            become Additional Services, which may be provided for a fee and such
            change shall become effective upon posting on the LowFloatTrading
            website or Nexus Platform.
            <br />
            "Basic Services" means Services available without registration of an
            Account.
            <br />
            "Content" means any files, data, material and information submitted,
            uploaded and stored by You through the Service.
            <br />
            "Platform" means the LFT Nexus online and mobile solution.
            <br />
            "Services" means any applications, products, services,
            documentation, and software made available through the Platform and
            includes both Basic Services and Additional Services.
            <br />
            "User Data" means all data relating to Your use of the Platform and
            Services, including but not limited to information related to:
            <br />
            • Your contact information, including email addresses,
            <br />
            • Information obtained by or provided through the Services,
            <br />
            • Settings, preferences chosen, and resource usage,
            <br />
            • Free text submitted by You.
            <br />
            "We", "Us", "LFT" or "Our" means LowFloatTrading
            <br />
            "You" or "Customer" means You or the company or other legal entity
            and its affiliates for which You are accepting these Terms.
            <br />
            <br />
            <b>The Services</b>
            <br />
            Access to the Platform. You may access and use the Services through
            the Platform solely for the purpose of Your own personal and
            educational noncommercial use. We may update the Services from time
            to time, including adding or removing functions.
            <br />
            Additional Services Registration. Following the initial registration
            of an Account You will have the ability to access Additional
            Services through the Platform.
            <br />
            Children under 18. The Services are not directed at children. If You
            are under 18 years old, please do not use or attempt to register to
            use the Services.
            <br />
            <br />
            <b>Registration and User Account</b>
            <br />
            Establishing an Account. You must register and establish an Account
            in order to use Additional Services. By registering an Account, You
            represent that You are entitled to disclose the information provided
            by You in such registration, without breach by You of any
            obligations or violation of any rights of third parties.
            <br />
            Account Information. You must safeguard and not disclose Your
            Account username and password and You must supervise the use of such
            Account. You must provide us accurate and complete information in
            order to create an Account. You agree to keep Your Account
            information up to date and accurate. YOU ARE SOLELY AND FULLY
            RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF THE ACCOUNT
            USERNAMES AND PASSWORDS. YOU ARE SOLELY AND FULLY RESPONSIBLE FOR
            ALL ACTIVITIES THAT OCCUR UNDER THE ACCOUNT. You must notify us
            immediately of any unauthorized use of Your Account or any other
            breach of security.
            <br />
            Deletion of Account. You may delete Your Account at any time. Any
            Content and other information and data entered into the Services may
            be permanently deleted if You delete the Account, provided we may
            retain certain information as required to comply with our legal
            obligations, resolve disputes and enforce our agreements (unless we
            are instructed otherwise).
            <br />
            Use of Data. We may collect User Data, and You hereby grant Us
            permission to collect User Data available on or through the Platform
            and to use such User Data to improve the Platform performance and
            functionality, improve services and support to Our customers and for
            other business purposes including monitoring, statistical data
            gathering, diagnostics, comparative analyses, press and supplies
            utilization, complementary solutions usage, security and software
            integrity assurance, remote control and support and click
            performance tracking and billing. We may use various analytics tools
            in performing the above. LFT does not provide an opt-out option from
            the use of such analytics tools and therefore You should refrain
            from registering an Account and from using any of the Service if You
            do not agree to the use of analytics tools in connection with the
            User Data. Where applicable, User Data collected by LFT will be
            shared with You or any service provider who may provide You with
            supplementary services in connection with the Services. LFT may
            further use User Data as set forth in its privacy policy referenced
            above.
            <br />
            Messages and Communications. We may contact You by sending You
            emails, SMS (text messages), Push Notifications (Web Push
            Notifications) or by calling Your telephone numbers or by other
            communication means, from time to time as deemed appropriate by us.
            Such messages constitute an integral part of our Services, and by
            accepting these Terms, You expressly consent to receive such
            messages and communications. Your cellular or mobile telephone
            provider may charge You according to the type of plan You carry.
            <br />
            If You do not wish to receive our messages and communications, You
            may notify us at any time by sending an email to:
            unsubscribe@lowfloattrading.com or by clicking on the 'unsubscribe'
            link in such message. Please note, your un-subscription from
            receiving messages and communications might impair your ability to
            use the Services (which require sending certain notifications to
            You). In addition, please note that, once we receive your request,
            it may take an additional period of time for the Unsubscribe Request
            to become effective.
            <br />
            Investors' request to receive notifications on investment
            opportunities, suggestions and offers: unless You have unsubscribed
            from receiving our messages and communications, your acceptance of
            these Terms constitutes a request from your side to receive further
            notifications from us regarding investment opportunities,
            suggestions and offers that may be relevant to you and to your use
            of our Services.
            <br />
            Neither LowFloatTradeing.com, our Discord server, “LFT“,
            “LowFloatTrading”, “Nexus” nor any related entity or associate is
            registered as an investment advisor nor a broker/dealer with either
            the SEC, FCA, CFTC, NFA, or any futures & securities regulatory
            authority. We do not have access to non-public information regarding
            publicly traded companies. Content contained in or made available
            through the Discord server, “LowFloatTrading” is not to be
            considered financial or investment advice. Users of this site and
            service are advised that all information presented is used solely
            for entertainment and educational purposes only, is not intended to
            be used as a personalized investment recommendation and is not
            attuned to any specific portfolio or to any user’s particular
            investment needs or objectives. The reader should seek the advice of
            a licensed securities professional before making any investment and
            should understand that that risk is inherent. The choice to invest
            any money is based solely on your own discretion.
            LowFloatTrading.com, “Nexus” or the Discord server,
            “LowFloatTrading” accepts no liability whatsoever for any loss due
            to the use of information provided. DO NOT make any automatic or
            manual trades, investments or bets based off of any information
            found in this application, website or Discord server
            “LowFloatTrading“ without seeking independent financial advice.
            <br />
            <br />
            <b>
              ANY ADVISORY NOTIFICATION OR SIGNAL GENERATED BY NEXUS, “LFT” OR
              OUR DISCORD SERVER “LOWFLOATTRADING” IS PROVIDED FOR EDUCATIONAL
              AND ENTERTAINMENT PURPOSES ONLY. ANY TRADES OR BETS PLACED UPON
              RELIANCE ON THIS DISCORD SERVER, “LOWFLOATTRADING” ARE TAKEN AT
              YOUR OWN RISK FOR YOUR OWN ACCOUNT. PAST PERFORMANCE IS NO
              GUARANTEE OF FUTURE RESULTS. WHILE THERE IS GREAT POTENTIAL FOR
              REWARD, THERE IS ALSO SUBSTANTIAL RISK OF LOSS IN ALL TRADING AND
              BETTING. YOU MUST DECIDE YOUR OWN SUITABILITY TO TRADE/BET OR NOT.
              FUTURE RESULTS CAN NEVER BE GUARANTEED. THIS IS NOT AN OFFER TO
              BUY OR SELL FUTURES OR COMMODITY INTERESTS.
            </b>
            <br />
            Please be aware of the risks associated with trading the financial
            markets; never invest more money than you can risk losing. The risks
            involved in trading are high and may not be suitable for all
            investors. The Nexus platform & LowFloatTrading doesn’t retain
            responsibility for any trading losses you might face as a result of
            using the data shown on its applications, website or webinars. The
            data and quotes contained may not be provided by exchanges but
            rather by market makers. So prices may be different from exchange
            prices and may not be accurate to real time trading prices. Any
            examples used are not a recommendation to buy or sell or a
            solicitation to buy or sell futures, options, bonds or binaries or
            securities of any kind.
            <br />
            The information that may be presented is based on trial or simulated
            trading using systems and education developed exclusively by The
            Nexus platform & LowFloatTrading. Simulated results do not represent
            actual trading. Please note that simulated trading results may or
            may not have been back-tested for accuracy and that
            spreads/commissions are not taken into account when preparing
            hypothetical results.
            <br />
            No representation is being made that any account will or is likely
            to achieve profits or losses similar to those that may be shown or
            advertised. Past performance is not indicative of future results.
            Individual results vary and no representation is made that clients
            will or are likely to achieve profits or incur losses comparable to
            those that may be shown.
            <br />
            <br />
            <b>Third Party Links</b>
            <br />
            Links to third-party sites are provided for your convenience. Such
            sites are not within our control and may not follow the same
            privacy, security, or accessibility standards as ours. The Nexus
            platform & LowFloatTrading neither endorses nor guarantees offerings
            of the third party providers, nor is The Nexus platform &
            LowFloatTrading responsible for the security, content or
            availability of third-party sites, their partners or advertisers.
            <br />
            <br />
            <b>Forex Disclaimer</b>
            <br />
            Before deciding to participate in the Forex market, you should
            carefully consider your investment objectives, level of experience
            and risk appetite. Most importantly, do not invest money you cannot
            afford to lose.
            <br />
            There is considerable exposure to risk in any off-exchange foreign
            exchange transaction, including, but not limited to, leverage,
            creditworthiness, limited regulatory protection and market
            volatility that may substantially affect the price, or liquidity of
            a currency or currency pair. Moreover, the leveraged nature of Forex
            trading means that any market movement will have an equally
            proportional effect on your deposited funds. This may work against
            you as well as for you. The possibility exists that you could
            sustain a total loss of initial margin funds and be required to
            deposit additional funds to maintain your position. If you fail to
            meet any margin requirement, your position may be liquidated and you
            will be responsible for any resulting losses.
            <br />
            There are risks associated with utilizing an Internet-based trading
            system including, but not limited to, the failure of hardware,
            software, and Internet connection. Any opinions, news, research,
            analyses, prices, or other information contained on this website are
            provided as general market commentary, and do not constitute
            investment advice. The Nexus platform & LowFloatTrading is not
            liable for any loss or damage, including without limitation, any
            loss of profit, which may arise directly or indirectly from use of
            or reliance on such information. The Nexus platform &
            LowFloatTrading has taken reasonable measures to ensure the accuracy
            of the information on the website. Our content is subject to change
            at any time without notice.
            <br />
            While every effort is made to check the accuracy of information
            contained on this website, The Nexus platform & LowFloatTrading
            cannot accept responsibility for any errors or omissions. We
            therefore strongly recommend that readers make their own thorough
            checks and seek independent financial advice before entering into
            any kind of transaction.
            <br />
            <br />
            <b>Nexus – Auto Trade Bot Disclaimer</b>
            <br />
            The Nexus application can connect to your broker and place trades on
            your behalf. Your broker is defined as a third-party service and by
            trading via the platform you additionally inherit the terms and
            conditions of their service. This service can be started and stopped
            at any time under your control. The choice to invest any money is
            based solely on your own discretion. LowFloatTrading.com, “Nexus” or
            the Discord server, “LowFloatTrading” accepts no liability
            whatsoever for any loss due to the use of information provided. DO
            NOT make any automatic or manual trades, investments or bets based
            off of any information found in this application, website or Discord
            server “LowFloatTrading“ without seeking independent financial
            advice. By using the Nexus application, you agree that any trades
            placed either automatically via the software or manually are
            authorized by you and you have conducted reasonable analysis /
            market research for the position/trade in question. All positions
            are opened/closed with your express authority and the Nexus
            application, LFT, LowFloatTrading, the discord server, website or
            any owner/developer or staff member within LowFloatTrading cannot be
            held accountable for any loss that you may encounter. <br />
            LFT highly recommends learning and applying risk management
            techniques - The application provides the ability for you to limit
            your risk within the user portal, any risk management strategy that
            you utilize is your sole responsibility to configure. <br />
            Risk Warning: Trading involves significant risk of loss to your
            capital.
            <br />
            <br />
            <b>Fees, Charges & Product Refund Policy</b>
            <br />
            Since your purchase is a digital product, it is deemed “used” upon
            first login, and all purchases made on lowfloattrading.com or via
            the Discord server are non-refundable or exchangeable. Since the
            products made available here are intangible, there is a strict no
            refund policy.
            <br />
            LowFloatTrading reserves the right to amend any information,
            including but not limited to prices, technical specifications, terms
            of purchase and product or service offerings without prior notice.
            <br />
            Order Term and Renewals – Unless otherwise specified on any
            applicable Quote or order, each Order Term shall automatically renew
            for additional one (1) month periods unless either party gives the
            other written notice of termination at least two (2) days prior to
            expiration of the then-current Order Term.
            <br />
            Fees and Payment – All fees are as set forth in the applicable Quote
            or Order and shall be paid by Customer in accordance with the
            invoice schedule and in the currency set forth in the applicable
            Quote or Order.
            <br />
            <br />
            <b>Delivery of Goods and Services</b>
            <br />
            If you do not receive the digital product link or activation details
            upon purchasing, you can immediately contact
            support@lowfloattrading.com with your transaction/payment details to
            ensure your product is delivered as soon as possible.
            <br />
            <br />
            <b>General Terms</b>
            <br />
            Changes to Terms. LFT may change the Terms from time to time, and
            such change will become effective upon the date on which it is
            posted on the Platform. You are responsible for checking the
            Platform regularly for such changes. By continuing to access or use
            the Services You agree to be bound by the revised Terms.
            <br />
            Severability. If any part of these Terms is deemed unlawful, void or
            for any reason unenforceable, then such part shall be deemed to be
            severable from the rest of these Terms and shall not affect the
            validity and enforceability of any of the remaining provisions of
            these Terms. In such cases, the part deemed invalid or unenforceable
            shall be construed in a manner consistent with applicable law to
            reflect, as closely as possible, the original intent of these Terms.
            <br />
            Waiver. No waiver by us of a breach of any of the provisions of
            these Terms shall be construed as a waiver of any preceding or
            succeeding breach of any of the provisions of these Terms.
            <br />
            Relationship. Nothing in these Terms or your use of the Services
            shall be construed as creating any agency, partnership, trust
            arrangement, fiduciary relationship or any other form of joint
            enterprise between You and LFT or anyone on its behalf.
            <br />
            <br />
            <b>Limitation of Liability</b>
            <br />
            IN NO EVENT WILL LowFloatTrading BE LIABLE FOR (I) INCIDENTAL,
            SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OR (II) LOSS OF PROFITS
            OR REVENUE, LOSS OF DATA, BUSINESS INTERRUPTION, OR LOSS OF
            GOODWILL, IN EACH CASE ARISING OUT OF OR RELATED TO YOUR USE OR
            INABILITY TO USE THE SERVICES, HOWEVER CAUSED, REGARDLESS OF THE
            THEORY OF LIABILITY (CONTRACT, TORT, OR OTHERWISE) AND EVEN IF LFT
            HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            <br />
            IN NO EVENT WILL LFT’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES
            ARISING OUT OF OR RELATED TO THESE TERMS OR TO THE SERVICES EXCEED
            AN AMOUNT OF US$1.00.
            <br />
            THESE LIMITATIONS WILL NOT APPLY TO THE EXTENT PROHIBITED BY LAW.
            <br />
            <br />
            <b>Term and Termination</b>
            <br />
            Term. These Terms commence on the date You first accept them and
            will remain in effect until these Terms are terminated. Acceptance
            is deemed as creating an account. <br />
            Termination. You may stop using the Services at any time and You may
            delete Your Account. We may suspend or terminate Your access to the
            Services at any time at our discretion and without notice if You do
            not comply with these Terms. Upon termination of the Services to
            You, the Account will be terminated, and from the date of
            termination You will no longer be able to access Your Account.
            <br />
            Survival. The provisions of these Terms that, by their nature and
            content, must survive the termination of these Terms in order to
            achieve the fundamental purposes of these Terms, shall so survive.
            Without limiting the generality of the foregoing, the Intellectual
            Property Rights, Third Party Material, Disclaimers of Warranties,
            Indemnification, Limitation of Liability, Governing Law and
            Jurisdiction and General sections, will survive the termination or
            expiration of the Terms.
            <br />
            <br />
            Contact Us if you have an enquiry or concern about our disclaimer.
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Copyright;
