import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes />
      <div id="version" style={{display: "none"}}>
        <p>
          
          {process.env.REACT_APP_BUILD_VERSION
            ? `BuildVersion: v${process.env.REACT_APP_BUILD_VERSION}`
            : "BuildVersion: Dev"}
        </p>
      </div>
    </Router>
  </React.StrictMode>,

  document.getElementById("root")
);
