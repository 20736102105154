import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(1, 0, 0),
  },
  grid: {
    alignItems: "right",
  },
  logoHome: {
    maxWidth: 400,
  },
  logo: {
    maxWidth: 200,
  },
}));

function Theme() {
  //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const prefersDarkMode = false;
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: { main: "#1976d2" },
          secondary: { main: "#ffd180" },
        },
      }),
    [prefersDarkMode]
  );

  return theme;
}
export default Theme;
