import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import Homepage from "./components/Homepage";
import AlpacaTradeConfig from "./components/AlpacaTradeConfig";
import Dashboard from "./components/Dashboard";
import history from "./history";
import { Redirect } from "react-router-dom";

export default function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/config" render={() => <AlpacaTradeConfig />} />
        <Route render={() => <Redirect to={{ pathname: "/" }} />} />
      </Switch>
    </Router>
  );
}
